import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import DemoCarousel from "./rcarousel";
import Menu from "./menu";
import TOC from "./toc";
import contentsen from "../data/en/data.json";
import contentsar from "../data/ar/data.json";
import Audio from "./mediaplayer/Audio";
const MainNav = () => {
  const imagesAr = [
    { src: "/images/landing1_ar.png" },
    { src: "/images/landing2_ar.png" },
    { src: "/images/landing3_ar.png" },
    { src: "/images/landing4_ar.png" },
  ];

  const imagesEn = [
    { src: "/images/landing1_en.png" },
    { src: "/images/landing2_en.png" },
    { src: "/images/landing3_en.png" },
    { src: "/images/landing4_en.png" },
  ];
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Menu />
          </Route>
          <Route
            exact
            path="/toc/:lang/:partNo/:chapterNo"
            children={<TocRender />}
          />
          <Route
            exact
            path="/audio/:lang/:partNo/:chapterNo"
            children={<AudioRender />}
          />
          {/* <Route path="/:id" children={<Child />} />
            <TOC content={contentsar} direction="rtl" />
          </Route> */}
          <Route exact path="/landingAr">
            <DemoCarousel isRTL={true} images={imagesAr} />
          </Route>
          <Route exact path="/landingEn">
            <DemoCarousel isRTL={false} images={imagesEn} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
function TocRender() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { lang, partNo, chapterNo } = useParams();
  if (lang === "ar") {
    return (
      <TOC
        lang={lang}
        content={contentsar}
        direction="rtl"
        partNo={partNo}
        chapterNo={chapterNo}
      />
    );
  } else {
    return (
      <TOC
        lang={lang}
        content={contentsen}
        direction="ltr"
        partNo={partNo}
        chapterNo={chapterNo}
      />
    );
  }
}

function AudioRender() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { lang, partNo, chapterNo } = useParams();
  if (lang === "ar") {
    return (
      <Audio
        lang={lang}
        content={contentsar}
        direction="rtl"
        partNo={partNo}
        chapterNo={chapterNo}
      />
    );
  } else {
    return (
      <Audio
        lang={lang}
        content={contentsen}
        direction="ltr"
        partNo={partNo}
        chapterNo={chapterNo}
      />
    );
  }
}
export default MainNav;
