import React from "react";
import { PlayCircleFilled } from "@material-ui/icons";

export default function Play(props) {
  const { handleClick } = props;

  return (
    <button className="player__button" onClick={() => handleClick()}>
       <PlayCircleFilled/>
      {/* <PlayCircleFilled style={{fill: "green"}}/> */}
      {/* <img
          alt="title"
          style={{ maxWidth: "50%", padding: "20px", maxHeight: "45%" }}
          src="/images/play.png"
        /> */}
    </button>
  );
}
