import "./App.css";
//import DemoCarousel from "./components/rcarousel";
import MainNav from "./components/mainnav";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-212806881-2"; // UA-212806881-1 YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  //return DemoCarousel();
  return MainNav();
}

export default App;
