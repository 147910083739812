import React, { useState, useEffect } from "react";
import { Accordion, AccordionItem } from "react-sanfona";
import { useHistory } from "react-router-dom";
import "./toc.css";
//import contentsen from "../data/en/data.json";

const TOC = (props) => {
  const history = useHistory();
  const handleClick = (partNo, chapterNo) => {
    history.push(`/audio/${props.lang}/${partNo}/${chapterNo}`);
  };
  const [chapterNo, setChapaterNo] = useState(props.chapterNo);
  const handleChange = (obj) => {
    setChapaterNo(-1);
    //console.log(obj.activeItems[0]);
    //console.log(contentsen.contentsen.part);
  };

  useEffect(() => {
    setChapaterNo(props.chapterNo);
  }, [props.chapterNo]);

  return (
    <div className="main_div">
      {props.lang === "ar" ? (
        <div className="header_title_ar">.لــم أنــتــه.</div>
      ) : (
        <div className="header_title">.I'M NOT DONE.</div>
      )}

      <div className="toc_Container" style={{ direction: props.direction }}>
        {props.lang === "ar" ? (
          <div className="toc_title_ar">الفهرس</div>
        ) : (
          <div className="toc_title">TABLE OF CONTENT</div>
        )}
        <Accordion onChange={handleChange}>
          {props.content.contents.part.map((part) => {
            return (
              <AccordionItem
                title={`${part.partName}`}
                expanded={part.partNumber === parseInt(props.partNo)}
              >
                {part.chapter.map((chapter) => (
                  <div
                    onClick={() =>
                      handleClick(part.partNumber, chapter.chapterNumber)
                    }
                    className={`react-sanfona-item-subtitle  ${
                      parseInt(chapterNo) === chapter.chapterNumber
                        ? "selected"
                        : ""
                    }`}
                  >
                    {chapter.chapterName}
                  </div>
                ))}
              </AccordionItem>
            );
          })}
        </Accordion>
        {/*orginal */}
        {/* <Accordion onChange={handleChange}>
        {[1, 2, 3, 4, 5].map((item) => {
          return (
            <AccordionItem title={`Item ${item}`} expanded={item === 2}>
              <div onClick={handleClick}>{`Item ${item} content`}</div>
              <div>{`Item ${item} content`}</div>
              <div>{`Item ${item} content`}</div>
              <div>{`Item ${item} content`}</div>
            </AccordionItem>
          );
        })}
      </Accordion> */}
      </div>
    </div>
  );
};
export default TOC;
