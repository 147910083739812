import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//import Song from "./Song";
import Play from "./Play";
import Next from "./Next";
import Prev from "./Prev";
import Pause from "./Pause";
import Bar from "./Bar";
import "./styles.scss";
import "../../fonts/bebas.ttf";

import useAudioPlayer from "./useAudioPlayer";

function Audio(props) {
  //   useEffect(() => {
  //   return () => {
  //     window.onpopstate = e => {
  //       console.log("back clicked")
  //       console.log(history)
  //    }

  //     // && history.location.pathname === "any specific path")
  //     // if (history.action === "POP") {
  //     //   console.log("back history")
  //     //   //history.replace(history.location.pathname, /* the new state */);
  //     // }
  //   };
  // })

  let {
    curTime,
    duration,
    playing,
    setPlaying,
    setReloadAudio,
    setClickedTime,
  } = useAudioPlayer();
  const [partNo, setPartNo] = useState(props.partNo);
  const [chapterNo, setChapaterNo] = useState(props.chapterNo);
  // useEffect(() => {
  //   setChapaterNo(props.chapterNo);
  // }, [props.chapterNo]);
  const history = useHistory();
  //const chapterNoRef = React.createRef();
  useEffect(() => {
    return () => {
      // console.log(history)
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        console.log(chapterNo);
        history.replace(`/toc/${props.lang}/${partNo}/${chapterNo}`);
      }
    };
  }, [chapterNo]);
  const prevChapter = () => {
    let curChapterNo = parseInt(chapterNo);
    let curPartNo = parseInt(partNo);
    if (curChapterNo > 1) {
      curChapterNo--;
      setChapaterNo(curChapterNo);
      //setPlaying(false)
      setReloadAudio(true);
    }
    //if it is first chapter in the part try to go to the last chapter of the previous part
    else {
      if (curPartNo > 1) curPartNo--;
      setPartNo(curPartNo);
      const lastChapterNo =
        props.content.contents.part[curPartNo - 1].chapter.length;
      setChapaterNo(lastChapterNo);
      setReloadAudio(true);
    }
  };

  const nextChapter = () => {
    let curChapterNo = parseInt(chapterNo);
    let curPartNo = parseInt(partNo);
    const curChapterCount =
      props.content.contents.part[curPartNo - 1].chapter.length;
    if (curChapterCount > curChapterNo) {
      curChapterNo++;
      console.log(curChapterNo);
      setChapaterNo(curChapterNo);
      //setPlaying(false)
      setReloadAudio(true);
    } else {
      const curPartCount = props.content.contents.part.length;
      if (curPartCount > curPartNo) {
        curPartNo++;
        curChapterNo = 1;
        setPartNo(curPartNo);
        setChapaterNo(curChapterNo);
        setReloadAudio(true);
      }
    }
  };

  return (
    <div>
      <div className="main_div">
        {props.lang === "ar" ? (
          <div className="header_title_ar">.لــم أنــتــه.</div>
        ) : (
          <div className="header_title">.I'M NOT DONE.</div>
        )}
        {props.lang === "ar" ? (
          <div className="author_title_ar">أكرم مكناس</div>
        ) : (
          <div className="author_title">Akram Miknas</div>
        )}
        <div className="playerContainer">
          <div className="titleContainer">
            <br />
            <br />
            <img
              className="centerImg"
              alt="title"
              style={{ maxWidth: "40%", maxHeight: "45%" }}
              src="/images/bookicon.png"
            />
            <br />
            <div
              style={{ direction: props.direction }}
              className={`${
                props.lang === "ar" ? "chapter_title_ar" : "chapter_title"
              }`}
            >
              {
                props.content.contents.part[partNo - 1].chapter[chapterNo - 1]
                  .chapterName
              }
            </div>
          </div>
          <div className="player">
            <audio id="audio">
              <source
                src={`/sound/${props.lang}/${
                  props.content.contents.part[partNo - 1].chapter[chapterNo - 1]
                    .soundName
                }`}
              />
              Your browser does not support the <code>audio</code> element.
            </audio>
            {/* <Song
          songName="Instant Crush"
          songArtist="Daft Punk ft. Julian Casablancas"
        /> */}
            <div className="controls">
              <Bar
                curTime={curTime}
                duration={duration}
                onTimeUpdate={(time) => setClickedTime(time)}
              />

              <div className="navContainer">
                <Prev handleClick={() => prevChapter()} />
                {playing ? (
                  <Pause handleClick={() => setPlaying(false)} />
                ) : (
                  <Play handleClick={() => setPlaying(true)} />
                )}
                <Next handleClick={() => nextChapter()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Audio;
