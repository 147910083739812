import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { useHistory } from "react-router-dom";

const DemoCarousel = (props) => {
  //   const images = [9, 8, 7, 6, 5].map((number) => ({
  //     src: `https://placedog.net/${number}00/${number}00?id=${number}`,
  //   }));
  const history = useHistory();
  const handleTapped = () => {
    console.log("tapped");
  };

  const handleEnter = (lang, partNo, chapterNo) => {
    history.push(`/toc/${lang}/${partNo}/${chapterNo}`);
  };

  const getBackImage = () => {
    if (props.isRTL)
      return (
        <img
          style={{
            position: "absolute",
            bottom: "10%",
            left: "0px",
            zIndex: "3000",
            maxWidth: "15%",
            cursor: "pointer",
          }}
          src="/images/enter_ar.png"
          onClick={() => handleEnter("ar", 0, 0)}
        />
      );
    else
      return (
        <img
          style={{
            position: "absolute",
            bottom: "10%",
            right: "0px",
            zIndex: "3000",
            maxWidth: "15%",
            cursor: "pointer",
          }}
          src="/images/enter_en.png"
          onClick={() => handleEnter("en", 0, 0)}
        />
      );
  };
  return (
    <div>
      <Carousel
        canAutoPlay={false}
        hasSizeButton={false}
        //hasLeftButton="topLeft"
        //hasRightButton="topRight"
        hasIndexBoard="bottomCenter"
        isMaximized={true}
        images={props.images}
        isRTL={props.isRTL}
        hasThumbnails={true}
        onIndexChange={({ curIndex, curIndexForDisplay }) => {
          console.log(curIndex);
          //if (curIndex == 3) history.push(`/toc`);
        }}
        onTap={console.log("tappppped")}
        style={{ height: "100%", width: "100%" }}
      />
      {getBackImage()}
    </div>
  );
};

export default DemoCarousel;
