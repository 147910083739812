import React from "react";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function Next(props) {
  const { handleClick } = props;

  return (
    <button className="player__button" onClick={() => handleClick()}>
       <NavigateNextIcon/>
      {/* <PlayCircleFilled style={{fill: "green"}}/> */}
      {/* <img
          alt="title"
          style={{ maxWidth: "50%", padding: "20px", maxHeight: "45%" }}
          src="/images/play.png"
        /> */}
    </button>
  );
}
