import React from "react";
import { NavLink } from "react-router-dom";
const Menu = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "50vh",
          backgroundColor: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="logo"
          style={{ maxWidth: "15%" }}
          src="/images/main_logo.png"
        />
        <img
          alt="title"
          style={{ maxWidth: "50%", padding: "20px", maxHeight: "45%" }}
          src="/images/title_eng.png"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "50vh",
          backgroundColor: "#FFB300",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <nav style={{ textAlign: "center", maxWidth: "45%", maxHeight: "80%" }}>
          <NavLink exact to="/landingAr">
            <img
              alt="Book Arabic"
              style={{ maxWidth: "100%" }}
              src="/images/book_ar.png"
            />
          </NavLink>
        </nav>
        <nav style={{ textAlign: "center", maxWidth: "45%", maxHeight: "80%" }}>
          <NavLink exact to="/landingEn">
            <img
              alt="Book English"
              style={{ maxWidth: "100%" }}
              src="/images/book_en.png"
            />
          </NavLink>
        </nav>
      </div>
    </div>
  );
};
export default Menu;
